export async function getGalleryList(type = '') {
    const response = await fetch(`https://www.stambox.com/cgi-bin/gallery_list.cgi?type=${type}`);
    const data = await response.json();
    return data;
};

export async function getMediaList(folder, type = '') {
    const response = await fetch(`https://www.stambox.com/cgi-bin/media_list.cgi?folder=${folder}&type=${type}`);
    const data = await response.json();
    return data;
};
