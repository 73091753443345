import { useState } from 'react';

import LoginModal from './LoginModal';
import Navigation from './Navigation';
import classes from './MainHeader.module.css';

const MainHeader = (props) => {
  const [ loggedIn, setLoggedIn ] = useState(false);
  const [ displayModal, setDisplayModal ] = useState(false);
  const [ pwError, setPwError ] = useState(false);

  const checkPassword = (pw) => {
    //console.log('checkPassword running');

    if (pw === 'goddammit') {
      setLoggedIn(true);
      setDisplayModal(false);
      setPwError(false);
    } else {
      setPwError(true);
    }
  };

  const onLogin = () => {
    setDisplayModal(true);
    //console.log('logging IN! huzzaaaah');
  };

  const onLogout = () => {
    setLoggedIn(false);
    //console.log('logging OUT! weweeeee');
  };

  const clickOffModal = () => {
      setDisplayModal(false);
      setPwError(false);
  };

  return (
    <header className={classes['main-header']}>
      <h1>Stambo's Stuff</h1>
      {displayModal && (
        <LoginModal 
          passwordSubmitHandler={checkPassword}
          showError={pwError}
          dismissBackdrop={clickOffModal}
        />
      )}
      <Navigation 
        isLoggedIn={loggedIn}
        onLogout={onLogout}
        onLogin={onLogin} 
        clickHandler={props.clickHandler}
      />
    </header>
  );
};

export default MainHeader;
