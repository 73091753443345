
const spaceApiKey = 'fc509e03-b546-4b04-81f8-64e65ef32585';

export async function postSpaceWeather(endpoint, postOptions = {}) {
    const postData = {
        api_key: spaceApiKey
    };
    if (Object.keys(postOptions).length) {
        postData.options = postOptions;
    }
    const fetchOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(postData)
    };
    const response = await fetch(`https://sws-data.sws.bom.gov.au/api/v1/${endpoint}`, fetchOptions);
    const data = await response.json();
    return data;
};

export const shitKIndexResponse = {
    "data": []
};

export const auroraOutlook = {
    "data": [
        {}
    ]
};

export const auroraWatch = {
    "data": [
        {
            "issue_time": "2021-09-30 03:46:40",
            "start_date": "2021-10-01",
            "end_date": "2021-10-01",
            "cause": "CME",
            "k_aus": "5",
            "lat_band": "high",
            "comments": "A coronal mass ejection is expected to impact the Earth within the next 48 hours, likely resulting in moderate geomagnetic activity and visible auroras during local nighttime hours on 01 October. Aurora alerts will follow if significant geomagnetic activity actually occurs."
        }
    ]
};

export const auroraAlert = {
    "data": [
        {
            "start_time": "2021-10-12 08:23:01",
            "valid_until": "2021-10-12 20:23:01",
            "kaus": "6",
            "lat_band": "high",
            "description": "GEOMAGNETIC STORM IN PROGRESS. AURORA MAY BE OBSERVED DURING LOCAL NIGHT TIME HOURS IN GOOD OBSERVING CONDITIONS AT HIGH LATITUDES."
        }
    ]
};

export const kIndexLocations = [
    "Australian region",
    "Alice Springs",
    "Canberra",
    "Casey",
    "Cocos Island",
    "Narrabri",
    "Darwin",
    "Davis",
    "Hobart",
    "Launceston",
    "Learmonth",
    "Macquarie Island",
    "Mawson",
    "Melbourne",
    "Norfolk Island",
    "Perth",
    "Sydney",
    "Townsville"
];