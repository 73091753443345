import { useState } from 'react';
import ReactDOM from 'react-dom';

import './App.css';
import AuroraBot from './components/Aurora/AuroraBot';
import Gallery from './components/Gallery/Gallery';
import ParkrunnerList from './components/Parkrun/ParkrunnerList';
import MainHeader from './components/MainHeader/MainHeader';
import { getParkrunnerData } from './data/Parkrunners';

function App() {
  const [activeApp, setActiveApp ] = useState('parkrun');
  const runners = getParkrunnerData();

  const handleNavClick = (appName) => {
    setActiveApp(appName.toLowerCase());
  };

  return (
    <div className="App">
      {ReactDOM.createPortal(
        <MainHeader clickHandler={handleNavClick} />,
        document.getElementById('header-root')
      )}
      {activeApp === 'parkrun' && ReactDOM.createPortal(
        <ParkrunnerList runners={runners} />,
        document.getElementById('parkrun-root')
      )}
      {activeApp === 'aurora' && ReactDOM.createPortal(
        <AuroraBot />,
        document.getElementById('aurora-root')
      )}
      {activeApp === 'gallery' && ReactDOM.createPortal(
        <Gallery />,
        document.getElementById('gallery-root')
      )}
    </div>
  );
}

export default App;
