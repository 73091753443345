import styles from './ParkrunnerList.module.css';
import BannerImage from '../UI/BannerImage';
import Parkrunner from './Parkrunner';

const ParkrunnerList = (props) => {
    return (
        <>
            <BannerImage title='parkrun' />
            <div className={styles['parkrunner-list']}>
                <ul>
                    {props.runners.map(runner => (
                        <Parkrunner
                            id={runner.id}
                            type={runner.type}
                            name={runner.name}
                            nickname={runner.nickname}
                            parrot={runner.parrotType}
                            key={runner.id}
                        />
                    ))}
                </ul>
            </div>
        </>
    );
}

export default ParkrunnerList;