
export async function getWeather(locale) {
    const response = await fetch(`https://www.stambox.com/cgi-bin/forecast.cgi?location=${locale}`);
    const data = await response.json();
    return data;
};

export function getWeatherEmoji(description) {
    let emoji = '🤷‍♂️'
    let desc = description.toLowerCase();

    // clouds
    if (desc.includes('cloudy')) {
        emoji = '☁️';
        if (desc.includes('partly')) {
            emoji = '🌥️';
        }
    }

    // clear
    if (desc.includes('clear')) {
        emoji = '☀️';
    }

    // rain
    if (desc.includes('shower') || desc.includes('rain') || desc.includes('drizzle')) {
        emoji = '️🌧️';
    }

    // sun
    if (desc.includes('sunny')) {
        emoji = '☀️️';
        if (desc.includes('mostly')) {
            emoji = '🌤️';
        }
    }

    return emoji;
}


export const weatherLocations = [
    "Burnie",
    "Devonport",
    "Hobart",
    "Launceston",
    "Penguin",
    "Stanley"
];
