import { getWeatherEmoji } from "../../utilities/weather-api";
import styles from "./WeatherDay.module.css";

const WeatherDay = (props) => {

    const date = new Date(props.date);
    const formattedDate = `${date.getMonth()+1}/${date.getDate()}`;

    return (
        <li className={styles['weather-li']}>
            <div className={styles.dateHeader}>{formattedDate}</div>
            <div className={styles.emojiDisplay} title={props.summary}>{getWeatherEmoji(props.summary)}</div>
            <div className={styles.infoText}>{props.low}&deg; - {props.high}&deg;</div>
            <div className={styles.infoText}>{props.rainChance} rain</div> 
        </li>
    )
}

export default WeatherDay;