import { useState, useEffect } from "react";
//import { testJson } from '../../data/GalleryTest';
//import loadingIcon from '../../assets/ui/loading.gif';
import LightGallery from 'lightgallery/react';

import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-video.css';
import 'lightgallery/css/lg-zoom.css';

import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgVideo from 'lightgallery/plugins/video';
import lgZoom from 'lightgallery/plugins/zoom';

import pictureIcon from '../../assets/ui/pictureicon.jpg';
import videoIcon from '../../assets/ui/videoicon.jpg';
import styles from './Album.module.css';

import { getMediaList } from '../../utilities/gallery-api';

const Album = (props) => {
    const [ mediaJson, setMediaJson ] = useState([]);

    //console.log(`Album component is rendering with ${props.folder}`);
    //const mediaResponse = getMediaList(props.folder);
    //console.log(mediaResponse);
    //setMedia(mediaResponse);

    useEffect(() => {
        const fetchMediaObjects = async () => {
            let responseData = await getMediaList(props.folder);

            setMediaJson(responseData);
        };
        fetchMediaObjects();
    }, [ props.folder ]);

    const onInit = () => {
        //console.log(`LightGallery has been initialized! folder = ${props.folder}`);
    };

    return (
        <>
            <h2>{props.folder.replace(/_/g, ' ')}</h2>
            <LightGallery
                onInit={onInit}
                speed={500}
                plugins={[lgThumbnail, lgZoom, lgVideo]}
            >
                {mediaJson.map(media => {
                    if (media.fileExt.toLowerCase() === 'mp4') {
                        const videoData = {
                            source: [
                                {
                                    src : media.filePath,
                                    type: "video/mp4"
                                }
                            ],
                            attributes: {
                                preload: false, 
                                controls: true,
                                playsinline: true
                            }
                        };
                        return (
                            <a data-video={JSON.stringify(videoData)} href={media.filePath}>
                                <img className={styles.thumbnailIcon} alt={media.fileName} src={videoIcon} />
                            </a>
                        )
                    }
/*                     if (props.folder == 'Australian_Life_January_2023' || props.folder == 'Disney_Half_Marathon_2012') { */
                        return (
                            <a data-src={media.filePath} href={media.filePath}>
                                <img className={styles.thumbnailIcon} alt={media.fileName} src={media.fileThumbPath} />
                            </a>
                        )
/*                     } */
                    return (
                        <a data-src={media.filePath} href={media.filePath}>
                            <img className={styles.thumbnailIcon} alt={media.fileName} src={pictureIcon} />
                        </a>
                    )
                })}
            </LightGallery>
        </>
    )
};

export default Album;