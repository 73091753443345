export const getParkrunnerData = () => {
    return [
        { 
            id: 6488988, 
            type: 'athlete',
            name: 'Jeffrey Stamborski', 
            nickname: 'Goddammit', 
            parrotType: 'jeff' 
        },
        { 
            id: 3016489, 
            type: 'athlete',
            name: 'Madison McBride', 
            nickname: 'Madd Dogg', 
            parrotType: 'mexican' 
        },
        { 
            id: 46740, 
            type: 'club',
            name: 'Queensland Inline Hockey', 
            nickname: 'QLD Hockey', 
            parrotType: 'australia' 
        }
    ];
}