import { useState } from 'react';
import styles from './Parkrunner.module.css';
import aussieParrot from '../../assets/parrots/australia.gif';
import jeffParrot from '../../assets/parrots/jeff.gif';
import mexicanParrot from '../../assets/parrots/mexican.gif';

const Parkrunner = (props) => {
  const [ displayName, setDisplayName ] = useState(props.nickname);
  const [ isNickname, setIsNickname ]   = useState(true);

  const getParrotObject = (parrotType) => {
    if (parrotType === 'jeff') {
      return jeffParrot;
    } else if (parrotType === 'mexican') {
      return mexicanParrot;
    }

    return aussieParrot;
  }

  let athleteSummaryLink = `https://www.parkrun.com.au/results/athleteresultshistory/?athleteNumber=${props.id}`;
  let athleteFullLink = `https://www.parkrun.com.au/parkrunner/${props.id}/all`;
  let linkContent = (
    <div>
      <a href={athleteFullLink} target='_blank' rel='noreferrer'>Full</a>
      <a style={{marginLeft: '30px'}}href={athleteSummaryLink} target='_blank' rel='noreferrer'>Summary</a>
    </div>
  );

  if (props.type === 'club') {
    linkContent = (
      <a 
        href={`https://www.parkrun.com/results/consolidatedclub/?clubNum=${props.id}`}
        target='_blank' 
        rel='noreferrer'
      >Results</a>
    );
  }

  const nameClickHandler = () => {
    if (isNickname) {
      setDisplayName(props.name);
      setIsNickname(false);
    } else {
      setDisplayName(props.nickname);
      setIsNickname(true);
    } 
  }
  
  return (
    <li>
      <div className={styles.runnerCard}>
          <p onClick={nameClickHandler}>{displayName}</p>
          <div className={styles['parrot-container']}>
              <img src={getParrotObject(props.parrot)} alt={props.parrot} />
          </div>
          {linkContent}
      </div>
    </li>
  );
}

export default Parkrunner;