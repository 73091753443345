import { useState, useEffect } from "react";
import loadingIcon from '../../assets/ui/loading.gif';
import styles from './WeatherCard.module.css';
import Card from "../UI/Card";
import { getWeather, weatherLocations } from "../../utilities/weather-api";
import WeatherDay from "./WeatherDay";

const WeatherCard = (props) => {
    const [ rawData, setRawData ] = useState({});
    const [ showRawData, setShowRawData ] = useState(false);
    const [ location, setLocation ] = useState(props.location);
    const [ isLoading, setIsLoading ] = useState(true);

    useEffect(() => {
        const fetchWeatherData = async () => {
            let responseData = await getWeather(location);

            setRawData(responseData);
            setIsLoading(false);
        };
        fetchWeatherData();
    }, [ location ]);

    const locationChangeHandler = (event) => {
        //console.log(event.target.value);
        //console.log('location changed!');
        setIsLoading(true);
        setLocation(event.target.value);
    };

    const rawDataClickHandler = () => {
        if (showRawData) {
            setShowRawData(false);
        } else {
            setShowRawData(true);
        }
    };

    const rawDataLinkText = `${showRawData ? 'hide' : 'show'} raw data`;
    let rawDataText = '';
    if (showRawData) {
        rawDataText = JSON.stringify(rawData, null, 2);
    }

    return (
        <Card className={styles['aurora-card']}>
            <header className={styles.header}>{props.title}</header>
            <div className={styles.dropdownArea}>
                <label htmlFor="locale">Location: </label>
                <select id="locale" className={styles.localeSelector} onChange={locationChangeHandler} defaultValue={location}>
                    {weatherLocations.map(location => (
                        <option key={location}>{location}</option>
                    ))}
                </select>
            </div>
            {isLoading ? 
                <img id='showLoading' className={styles.loadingSpinner} src={loadingIcon} alt='...loading'/> 
                : 
                <div>
                    <ul className={styles['weather-ul']}>
                        {rawData.slice(2, 6).map(dailyData => {
                            return (
                                <WeatherDay 
                                    key={dailyData.date} 
                                    date={dailyData.date} 
                                    high={dailyData.high} 
                                    low={dailyData.low} 
                                    rainChance={dailyData.chance_of_rain} 
                                    summary={dailyData.description} 
                                />
                            )
                        })}
                    </ul>
                    <div className={styles.rawDataView}>{rawDataText}</div>
                </div>
            }
            <p className={styles.rawTextLink} onClick={rawDataClickHandler}>{rawDataLinkText}</p>
        </Card>
    );
};

export default WeatherCard;