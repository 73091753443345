import { useState, useEffect } from "react";
import { postSpaceWeather, kIndexLocations } from '../../utilities/space-weather-api';
import loadingIcon from '../../assets/ui/loading.gif';
import styles from './AuroraKIndexCard.module.css';
import Card from "../UI/Card";

const noDataDisplayText = 'NO DATA';

const AuroraKIndexCard = (props) => {
    const [ rawData, setRawData ] = useState({});
    const [ showRawData, setShowRawData ] = useState(false);
    const [ kIndex, setKIndex ] = useState(noDataDisplayText);
    const [ location, setLocation ] = useState(props.location);
    const [ isLoading, setIsLoading ] = useState(true);
    //console.log(`location A: ${location}`);

    useEffect(() => {
        //console.log('in useEffect for AuroraKIndexCard');
        const fetchSpaceWeatherData = async () => {
            let responseData = await postSpaceWeather('get-k-index', { location: location });

            setRawData(responseData);
            setKIndex(responseData.data.length > 0 ? responseData.data[0].index : noDataDisplayText);
            setIsLoading(false);
        };
        fetchSpaceWeatherData();
    }, [ location ]);

    const locationChangeHandler = (event) => {
        //console.log(event.target.value);
        //console.log('location changed!');
        setIsLoading(true);
        setLocation(event.target.value);
    };

    const rawDataClickHandler = () => {
        if (showRawData) {
            setShowRawData(false);
        } else {
            setShowRawData(true);
        }
    };

    const rawDataLinkText = `${showRawData ? 'hide' : 'show'} raw data`;
    let rawDataText = '';
    if (showRawData) {
        rawDataText = JSON.stringify(rawData, null, 2);
    }

    let valueColor = 'black';
    if (!isNaN(kIndex)) {
        const kNumeric = parseInt(kIndex);
        if (kNumeric <= 3) {
            valueColor = 'green';
        } 
        if (kNumeric === 4) {
            valueColor = 'yellow';
        } 
        if (kNumeric === 5) {
            valueColor = 'orange';
        } 
        if (kNumeric >= 6) {
            valueColor = 'red';
        } 
    }

    return (
        <Card className={styles['aurora-card']}>
            <header className={styles.header}>{props.title}</header>
            <div className={styles.dropdownArea}>
                <label htmlFor="locale">Location: </label>
                <select id="locale" className={styles.localeSelector} onChange={locationChangeHandler} defaultValue={location}>
                    {kIndexLocations.map(location => (
                        <option key={location}>{location}</option>
                    ))}
                </select>
            </div>
            {isLoading ? 
                <img id='showLoading' className={styles.loadingSpinner} src={loadingIcon} alt='...loading'/> 
                : 
                <div>
                    <p style={{ color: valueColor, fontSize: 'xxx-large', margin: '0rem' }}>{kIndex}</p>
                    <div className={styles.rawDataView}>{rawDataText}</div>
                </div>
            }
            <p className={styles.rawTextLink} onClick={rawDataClickHandler}>{rawDataLinkText}</p>
        </Card>
    );
};

export default AuroraKIndexCard;