import React from 'react';

import classes from './Navigation.module.css';

const Navigation = (props) => {
  const menuItems = ['Parkrun', 'Aurora'];

  let buttonWord = 'Login';
  let buttonAction = props.onLogin;

  if (props.isLoggedIn) {
    menuItems.push('Gallery');
    buttonWord = 'Logout';
    buttonAction = props.onLogout;
  }

  return (
    <nav className={classes.nav}>
      <ul>
        {menuItems.map(item => (
          <li key={item}>
            <div className={classes.navLink} onClick={() => props.clickHandler(item)}>{item}</div>
          </li>
        ))}
        <li>
          <button onClick={buttonAction}>{buttonWord}</button>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
