import { useState } from 'react';
import ReactDOM from 'react-dom';

import Card from '../UI/Card';
import styles from './LoginModal.module.css';

const Backdrop = (props) => {
  return (
      <div className={styles.backdrop} onClick={props.onDismiss} />
  );
};

const ModalOverlay = (props) => {
    const [ password, setPassword ] = useState('');

    const onPasswordChange = (event) => {
        //console.log('password is being changed');
        setPassword(event.target.value);
    };

    let error = '';
    if (props.showError) {
        error = '❌ Incorrect Password ❌';
    }

  const submitHandler = (event) => {
    //console.log('form submmited');
    event.preventDefault();
    props.passwordSubmitHandler(password);
  };

  return (
    <Card className={styles.modal}>
        <form onSubmit={submitHandler}>
            <header className={styles.header}>
                <h2>{props.title}</h2>
            </header>
            <div className={styles.content}>
                <label className={styles.label} htmlFor='password'>Password:</label>
                <input id='password' type='password' value={password} onChange={onPasswordChange} />
                <p className={styles.errorMessage}>{error}</p>
            </div>
            <footer className={styles.actions}>
                <button type="submit" className={styles.button}>Submit</button>
            </footer>
        </form>
    </Card>
  );
};

const LoginModal = (props) => {
    return (
        <>
            {ReactDOM.createPortal(
                <Backdrop onDismiss={props.dismissBackdrop} />, 
                document.getElementById('backdrop-root')
            )}
            {ReactDOM.createPortal(
                <ModalOverlay 
                    title='Enter Password'
                    passwordSubmitHandler={props.passwordSubmitHandler} 
                    showError={props.showError}
                />, 
                document.getElementById('overlay-root')
            )}
        </>
    );
};

export default LoginModal;