import { useState, useEffect } from "react";
import { postSpaceWeather } from '../../utilities/space-weather-api';
//import { postSpaceWeather, auroraWatch, auroraAlert } from '../../utilities/space-weather-api';
import loadingIcon from '../../assets/ui/loading.gif';
import styles from './AuroraCard.module.css';
import Card from "../UI/Card";

const happyText = 'YES!';
const sadText = 'none';

const AuroraCard = (props) => {
    const [ rawData, setRawData ] = useState({});
    const [ keyValue, setKeyValue ] = useState('');
    const [ showRawData, setShowRawData ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(true);

    useEffect(() => {
        const fetchSpaceWeatherData = async () => {
            let responseData = await postSpaceWeather(props.type);

            /* MOCKING STUFFS */
/*             if (props.type === 'get-aurora-alert') {
                responseData = auroraAlert;
            } else if (props.type === 'get-aurora-watch') {
                responseData = auroraWatch;
            } */

            setRawData(responseData);
            setKeyValue(responseData.data[0] !== undefined ? true : false);
            setIsLoading(false);
        };
        fetchSpaceWeatherData();
    }, [ props.type ]);

    const rawDataClickHandler = () => {
        if (showRawData) {
            setShowRawData(false);
        } else {
            setShowRawData(true);
        }
    };

    const rawDataLinkText = `${showRawData ? 'hide' : 'show'} raw data`;
    let rawDataText = '';
    if (showRawData) {
        rawDataText = JSON.stringify(rawData, null, 2);
    }

    return (

        <Card className={styles['aurora-card']}>
            <div className={keyValue ? styles.happyGreen : null}>
                <header className={styles.header}>{props.title}</header>
                {isLoading ? 
                    <img id='showLoading' className={styles.loadingSpinner} src={loadingIcon} alt='...loading'/> 
                    : 
                    <div>
                        <p className={styles.keyValueText}>{keyValue ? happyText : sadText}</p>
                        <div className={styles.rawDataView}>{rawDataText}</div>
                    </div>
                }
                <p className={styles.rawTextLink} onClick={rawDataClickHandler}>{rawDataLinkText}</p>
            </div>
        </Card>
    );
};

export default AuroraCard;