import { useState, useEffect } from 'react';
import { getGalleryList } from '../../utilities/gallery-api';
import styles from './Gallery.module.css';
import loadingIcon from '../../assets/ui/loading.gif';
import Album from './Album';

const Gallery = () => {
    const [ currentGallery, setCurrentGallery ] = useState('');
    const [ galleries, setGalleries ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(true);

    useEffect(() => {
        //console.log('in use effect! ZZZ');
        const fetchGalleryList = async () => {
            let responseData = await getGalleryList();

            setGalleries(responseData);
            setIsLoading(false);
        };
        fetchGalleryList();
    }, [ ]);

    const clickHandler = (event) => {
        const folder = event.target.value;
        //console.log(`${folder} button was clicked`);

        setCurrentGallery(folder);
    }
    
    //console.log(`currentgallery = ${currentGallery}`);
    //console.log(`currentgallery.length = ${currentGallery.length}`);

    return (
        <div className={styles.gallery}>
            <h1>Galleries</h1>
{/*             <h2>current gallery = {currentGallery}</h2> */}
            {isLoading ? 
                <h1><img id='showLoading' className={styles.loadingSpinner} src={loadingIcon} alt='...loading'/></h1>
                : 
                <ul className={styles.galleryList}>
                    {galleries.map(gallery => {
                        return (
                            <div className={styles.listButtonContainer}>
                                <li className={styles.listItem} key={gallery.folder}>
                                    <button 
                                        className={styles.listButton} 
                                        value={gallery.folder} 
                                        onClick={clickHandler}
                                    >{gallery.displayName}</button>
                                </li>
                            </div>
                        );
                    })}
                </ul>
            }
            <h1>Album</h1>
            {currentGallery.length > 0 ? 
                <Album isLoading={true} folder={currentGallery} />
                :
                <p className={styles.defaultText}>Select a gallery</p>
            }
        </div>
    );
}

export default Gallery;