import styles from './BannerImage.module.css';
import parkrunBanner from '../../assets/banners/parkrun.jpg';
import tassieBanner from '../../assets/banners/tassie.jpg';

const getBannerObject = (type) => {
    if (type === 'tassie') {
        return tassieBanner;
    }

    return parkrunBanner;
}

const BannerImage = (props) => {
    return (
        <div className={styles['banner-container']}>
            <img src={getBannerObject(props.title)} alt={props.title} />
        </div>
    )
}

export default BannerImage;