import AuroraCard from './AuroraCard';
import AuroraKIndexCard from './AuroraKIndexCard';
import BannerImage from '../UI/BannerImage';
import WeatherCard from './WeatherCard';

const defaultLocation = 'Hobart';

const AuroraBot = () => {
    return (
        <>
            <BannerImage title='tassie' />
            <AuroraKIndexCard title='K Index' type='get-k-index' location={defaultLocation} />
            <WeatherCard title='Weather Forecast' location={defaultLocation} />
            <AuroraCard title='3-7 Day Outlook' type='get-aurora-outlook' />
            <AuroraCard title='48 Hour Watch'   type='get-aurora-watch' />
            <AuroraCard title='Current Alerts'  type='get-aurora-alert' />
        </>
    );
}

export default AuroraBot;